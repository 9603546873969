import React, { useState, useEffect } from 'react';
import { Terminal, Cloud, Code, Brain, Smartphone, Database, Users, ShieldCheck } from 'lucide-react';
import emailjs from 'emailjs-com';

const TypewriterEffect = ({ words }) => {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [blink, setBlink] = useState(true);


  useEffect(() => {
    if (subIndex === words[index].length + 1 && !reverse) {
      setReverse(true);
      return;
    }

    if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex((prev) => (prev + 1) % words.length);
      return;
    }

    const timeout = setTimeout(() => {
      setSubIndex((prev) => prev + (reverse ? -1 : 1));
    }, Math.max(reverse ? 50 : subIndex === words[index].length ? 1000 : 100, parseInt(Math.random() * 200)));

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse, words]);

  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearTimeout(timeout2);
  }, [blink]);

  return (
      <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-6 text-white leading-tight h-20">
        {`${words[index].substring(0, subIndex)}${blink ? "|" : " "}`}
      </h1>
  );
};

const WebpulseHomepage = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top: element.offsetTop - 80 // Ajusta este valor según la altura de tu header
      });
    }
  };
  
  
  
  
  //SE AGREGA CODIGO DE EMAIL AHC 30012025
  

  const [form, setForm] = useState({ name: "", email: "", message: "", captcha: "" });
  const [captchaValues, setCaptchaValues] = useState({ num1: 0, num2: 0, result: 0 });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    setCaptchaValues({ num1, num2, result: num1 + num2 });
    setForm({ ...form, captcha: "" });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!form.name || !form.email || !form.message) {
      setError("Por favor, completa todos los campos.");
      return;
    }

    if (parseInt(form.captcha) !== captchaValues.result) {
      setError("Captcha incorrecto. Intenta de nuevo.");
      generateCaptcha();
      return;
    }

    //const serviceID = "service_pzsqjog";
    //const templateID = "template_r5p7fqk";
    //const userID = "ySXs8jHthF3MQn2O6";
	
	const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    const templateParams = {
      from_name: form.name,
      from_email: form.email,
      message: form.message,
      to_email: "contacto@webpulse.mx",  // Asegurar que el destinatario es correcto
    };

    emailjs.send(serviceID, templateID, templateParams, userID)
      .then(() => {
        setSuccess("Mensaje enviado correctamente.");
        setForm({ name: "", email: "", message: "", captcha: "" });
        generateCaptcha();
      })
      .catch((err) => {
        console.error("Error al enviar el mensaje:", err);
        setError("Hubo un error al enviar el mensaje. Inténtalo de nuevo.");
      });
  };
  

  return (
      <div className="min-h-screen bg-white text-gray-800 font-sans">
        {/* Header sticky */}
        <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-blue-700 shadow-md py-2' : 'bg-transparent py-4'}`}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <img src="/logo_negativo-removebg-preview.png" alt="Webpulse Logo"
                     className={`transition-all duration-300 ${isScrolled ? 'h-10 sm:h-12' : 'h-12 sm:h-16'}`}/>
              </div>

              <nav className="hidden md:flex space-x-8">
                <a onClick={() => scrollToSection('home')}
                   className="text-white hover:text-purple-200 transition font-medium cursor-pointer">Inicio</a>
                <a onClick={() => scrollToSection('services')}
                   className="text-white hover:text-purple-200 transition font-medium cursor-pointer">Servicios</a>
                <a onClick={() => scrollToSection('about')}
                   className="text-white hover:text-purple-200 transition font-medium cursor-pointer">Sobre Nosotros</a>
                <a onClick={() => scrollToSection('contact')}
                   className="text-white hover:text-purple-200 transition font-medium cursor-pointer">Contacto</a>
              </nav>

              <button className="md:hidden text-white">
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                  <path d="M4 6h16M4 12h16M4 18h16" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>
          </div>
        </header>

        {/* Jumbotron con efecto de línea de comandos */}
        <div id='home' className="bg-gradient-to-r from-blue-700 to-purple-800 min-h-screen flex items-center justify-center">
          <div className="container mx-auto px-4 pt-20 pb-16">
            <div className="max-w-4xl mx-auto text-center">
              <div className="bg-black bg-opacity-50 p-4 sm:p-6 md:p-8 rounded-lg">
                <div className="flex items-center justify-start mb-4">
                  <div className="w-3 h-3 rounded-full bg-red-500 mr-2"></div>
                  <div className="w-3 h-3 rounded-full bg-yellow-500 mr-2"></div>
                  <div className="w-3 h-3 rounded-full bg-green-500"></div>
                </div>
                <p className="text-green-400 text-left mb-4 text-sm sm:text-base">$ webpulse --init</p>
                <TypewriterEffect words={[
                  "Innovación Tecnológica a tu Alcance",
                  "Transformamos Ideas en Soluciones Digitales",
                  "Potenciamos tu Negocio con IA y Cloud"
                ]} />
                <p className="text-base sm:text-lg md:text-xl mb-8 text-gray-300 text-left">
                  > Desarrollamos soluciones de vanguardia en IA, software y cloud.
                </p>
              </div>
              <div className="mt-8 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                <a href="#services" className="bg-white text-blue-700 font-bold py-2 px-6 sm:py-3 sm:px-8 rounded-full hover:bg-blue-100 transition duration-300 transform hover:scale-105">
                  Descubre nuestros servicios
                </a>
                <a href="#contact" className="bg-transparent border-2 border-white text-white font-bold py-2 px-6 sm:py-3 sm:px-8 rounded-full hover:bg-white hover:text-blue-700 transition duration-300 transform hover:scale-105">
                  Contáctanos
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Servicios */}
        <section id="services" className="py-16 sm:py-20 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl sm:text-4xl font-bold text-center text-gray-800 mb-8 sm:mb-12">Nuestros Servicios</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
              {[
                { icon: <Brain size={40} />, title: 'Inteligencia Artificial', description: 'Soluciones de IA que optimizan procesos y automatizan tareas complejas.' },
                { icon: <Code size={40} />, title: 'Desarrollo de Software', description: 'Creamos software a medida utilizando las últimas tecnologías.' },
                { icon: <Smartphone size={40} />, title: 'Desarrollo de Aplicaciones', description: 'Apps móviles y web intuitivas que conectan tu negocio con tus clientes.' },
                { icon: <Cloud size={40} />, title: 'Migración a la Nube', description: 'Transición segura y eficiente a la nube, optimizando tu infraestructura IT.' },
                { icon: <Terminal size={40} />, title: 'Servicios de TI', description: 'Soporte técnico integral y consultoría estratégica.' },
                { icon: <Database size={40} />, title: 'DRP', description: 'Planes de recuperación ante desastres para garantizar la continuidad de tu negocio.' },
              ].map((service, index) => (
                  <div key={index} className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition duration-300">
                    <div className="text-purple-600 mb-4 flex justify-center">{service.icon}</div>
                    <h3 className="text-lg sm:text-xl font-semibold mb-3 text-center">{service.title}</h3>
                    <p className="text-sm sm:text-base text-gray-600 text-center">{service.description}</p>
                  </div>
              ))}
            </div>
          </div>
        </section>

        {/* Por qué elegirnos */}
        <section id="about" className="py-16 sm:py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl sm:text-4xl font-bold text-center text-gray-800 mb-8 sm:mb-12">¿Por qué elegir Webpulse?</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                { icon: <Users size={40} />, title: 'Equipo Experto', description: 'Profesionales altamente calificados y apasionados por la tecnología.' },
                { icon: <ShieldCheck size={40} />, title: 'Confiabilidad', description: 'Nos comprometemos a entregar soluciones de calidad, cumpliendo plazos y superando expectativas.' },
                { icon: <Brain size={40} />, title: 'Innovación Constante', description: 'Siempre a la vanguardia, incorporando las últimas tecnologías en nuestras soluciones.' },
              ].map((item, index) => (
                  <div key={index} className="text-center">
                    <div className="text-purple-600 mb-4 flex justify-center">{item.icon}</div>
                    <h3 className="text-lg sm:text-xl font-semibold mb-3">{item.title}</h3>
                    <p className="text-sm sm:text-base text-gray-600">{item.description}</p>
                  </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA */}
        <section className="py-16 sm:py-20 bg-gradient-to-r from-blue-700 to-purple-800">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-white">Potencia tu negocio con Webpulse</h2>
            <p className="text-lg sm:text-xl mb-8 text-gray-200">Estamos listos para llevar tu empresa al siguiente nivel tecnológico.</p>
            <a href="#contact" className="bg-white text-blue-700 font-bold py-2 px-6 sm:py-3 sm:px-8 rounded-full hover:bg-blue-100 transition duration-300 inline-block">
              Solicita una consulta gratuita
            </a>
          </div>
        </section>

		{/* FORMULARIO CONTACTO */}
		<section id="contact" className="py-16 sm:py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl font-bold text-center text-gray-800 mb-8 sm:mb-12">Contáctanos</h2>
        <form className="max-w-lg mx-auto" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Nombre</label>
            <input
              type="text"
              id="name"
              value={form.name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
            <input
              type="email"
              id="email"
              value={form.email}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-6">
            <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Mensaje</label>
            <textarea
              id="message"
              rows="4"
              value={form.message}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="captcha" className="block text-gray-700 text-sm font-bold mb-2">
              ¿Cuánto es {captchaValues.num1} + {captchaValues.num2}?
            </label>
            <input
              type="number"
              id="captcha"
              value={form.captcha}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          {error && <p className="text-red-500 text-sm text-center mb-4">{error}</p>}
          {success && <p className="text-green-500 text-sm text-center mb-4">{success}</p>}

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
            >
              Enviar mensaje
            </button>
          </div>
        </form>
      </div>
    </section>


        {/* Footer */}
        <footer className="bg-gray-900 text-white py-12">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <div>
                <img src="/logo_negativo-removebg-preview.png" alt="Webpulse Logo" className="h-12 sm:h-16 mb-4" />
                <p className="text-sm">Innovación tecnológica para impulsar tu negocio hacia el futuro digital.</p>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-4">Servicios</h3>
                <ul className="text-sm space-y-2">
                  <li>Inteligencia Artificial</li>
                  <li>Desarrollo de Software</li>
                  <li>Migración a la Nube</li>
                </ul>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-4">Empresa</h3>
                <ul className="text-sm space-y-2">
                  <li><a href="/aviso-privacidad.html" target="_blank" className="hover:text-purple-300 transition">Aviso de Privacidad</a></li>
                </ul>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4">Contacto</h3>
                <ul className="text-sm space-y-2">
                  <li>Email: contacto@webpulse.mx</li>
                </ul>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-gray-800 text-center text-sm">
              <p>&copy; 2025 Webpulse. Todos los derechos reservados.</p>
            </div>
          </div>
        </footer>
      </div>
  );
};

export default WebpulseHomepage;